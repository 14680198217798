import { useCallback, useEffect, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useRefGetter } from '@ichingio/hooks';
import { wait } from '@ichingio/nn';
import { NavDropdown } from '@ichingio/ui';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
} from '~/lib/routes';

export const useSelectorVisibility = (scrollElement: HTMLDivElement) => {
  const [isVisible, toggleVisibility] = useState(false);
  const getIsVisible = useRefGetter(isVisible);

  useEffect(() => {
    if (scrollElement) {
      let prevScrollTop = scrollElement.scrollTop;
      const handleScroll: EventListener = () => {
        const current = getIsVisible();
        const threshold =
          Math.max(prevScrollTop - scrollElement.scrollTop, 0) > 128
            ? 1024
            : 64;
        const updates =
          scrollElement.scrollTop > threshold &&
          scrollElement.scrollTop <= prevScrollTop;

        if (current !== updates) {
          toggleVisibility(updates);
        }

        prevScrollTop = scrollElement.scrollTop;
      };

      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollElement, getIsVisible]);

  return isVisible;
};

export type SelectorDropdownProps = {
  size?: 'md' | 'lg';
  scrollElement: HTMLDivElement;
};

export const SelectorDropdown = (props: SelectorDropdownProps) => {
  const { size = 'md', scrollElement } = props;
  const [_, navigate] = useLocation();
  const [isDivinations] = useRoute(LIST_DIVINATIONS_ROUTE);
  const [isJournals] = useRoute(LIST_JOURNALS_ROUTE);
  const [isDivination] = useRoute(DIVINATION_ROUTE);
  const [isJournal] = useRoute(JOURNAL_ROUTE);
  let content: string | null = null;

  if (isDivinations || isDivination) {
    content = 'Divinations';
  }
  if (isJournals || isJournal) {
    content = 'Journals';
  }

  const scrollUpAndWait = useCallback(async () => {
    if (scrollElement && scrollElement.scrollTop > 50) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });

      return new Promise<void>((resolve) => {
        const checkScroll = async () => {
          if (scrollElement.scrollTop < 16) {
            await wait(168);
            resolve();
          } else {
            await wait(42);
            checkScroll();
          }
        };

        checkScroll();
      });
    }
  }, [scrollElement]);

  return (
    <NavDropdown.Root>
      <NavDropdown.Trigger tabIndex={1} ml="2px" size={size}>
        {content}
      </NavDropdown.Trigger>
      <NavDropdown.Content alignOffset={-4} minWidth="10rem">
        <NavDropdown.Item
          isSelected={isDivinations}
          onSelect={async () => {
            await scrollUpAndWait();

            if (!isDivinations) {
              navigate(LIST_DIVINATIONS_ROUTE);
            }
          }}
        >
          Divinations
        </NavDropdown.Item>
        <NavDropdown.Item
          isSelected={isJournals}
          onSelect={async () => {
            await scrollUpAndWait();

            if (!isJournals) {
              navigate(LIST_JOURNALS_ROUTE);
            }
          }}
        >
          Journals
        </NavDropdown.Item>
      </NavDropdown.Content>
    </NavDropdown.Root>
  );
};
