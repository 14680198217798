import {
  Content,
  default as Dialog,
  Overlay,
  Title,
  type DialogContentProps,
  type DialogProps,
} from './Dialog';
import useDialog from './hooks/useDialog';

export { useDialog, type DialogProps, type DialogContentProps };

export default Object.assign(Dialog, {
  Content,
  Overlay,
  Title,
});
