import * as RxPopover from '@radix-ui/react-popover';
import { animated, config, useTransition } from '@react-spring/web';
import {
  forwardRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { InnerIconButton } from '../Button/IconButton';

type PopoverContextValue = {
  isOpen: boolean;
  toggleOpen: (updates: boolean) => void;
};

const PopoverContext = createContext<PopoverContextValue>(
  {} as PopoverContextValue,
);

export const Arrow = styled(RxPopover.Arrow, { base: { fill: 'white' } });
export const Portal = styled(RxPopover.Portal, {});

const InnerContent = styled(animated(RxPopover.Content), {
  base: {
    background: 'white',
    borderRadius: 8,
    minWidth: 'fit-content',
    outline: 'none',
    py: 1.5,
    shadow: 'lg',
    width: '10.68rem',
    zIndex: 10,
  },
});

export type PopoverContentProps = Pick<
  RxPopover.PopoverContentProps,
  'align' | 'alignOffset' | 'side' | 'sideOffset' | 'children'
> &
  JsxStyleProps & {
    hasArrow?: boolean;
  };

const getSideOffset = (side: PopoverContentProps['side']) => {
  switch (side) {
    case 'bottom': {
      return -6;
    }
    case 'top': {
      return 6;
    }
    default: {
      return 0;
    }
  }
};

export const Content = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent(props, ref) {
    const {
      align = 'center',
      children,
      hasArrow = false,
      side = 'bottom',
      sideOffset = 0,
      ...contentProps
    } = props;
    const { isOpen } = useContext(PopoverContext);
    const transitions = useTransition(isOpen, {
      from: { opacity: 0.84, y: getSideOffset(side) },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: getSideOffset(side) },
      config: {
        ...config.default,
        mass: 1,
        tension: 200,
        friction: 26,
      },
      expires: true,
    });

    return (
      <Portal forceMount>
        {transitions((styles, shouldRender) =>
          shouldRender ? (
            <InnerContent
              align={align}
              forceMount
              ref={ref}
              side={side}
              sideOffset={sideOffset}
              style={styles}
              {...contentProps}
            >
              {children}
              {hasArrow && <Arrow />}
            </InnerContent>
          ) : null,
        )}
      </Portal>
    );
  },
);

export type PopoverTriggerProps = RxPopover.PopoverTriggerProps & JsxStyleProps;
export const Trigger = styled(RxPopover.Trigger, {
  base: {
    opacity: 1,
    outline: 'none',
    '&[data-state="open"]': {
      opacity: 0.32,
    },
    '&[data-state="closed"]': {
      opacity: 1,
    },
  },
});

export type PopoverAnchorProps = RxPopover.PopoverAnchorProps & JsxStyleProps;
export const Anchor = styled(RxPopover.Anchor);

export type PopoverIconTriggerProps = RxPopover.PopoverTriggerProps &
  JsxStyleProps;

// Note: we must use the inner icon button component here instead of the Aria composition so that Radix can bind its own handlers.
export const IconTrigger = forwardRef<
  HTMLButtonElement,
  PopoverIconTriggerProps
>(function IconTrigger(props, ref) {
  return (
    <Trigger asChild>
      <InnerIconButton {...props} ref={ref} />
    </Trigger>
  );
});

type CommonnPopoverProps = Omit<RxPopover.PopoverProps, 'open' | 'modal'>;
export type PopoverProps = CommonnPopoverProps & {
  isOpen?: boolean;
  onClose?: () => void;
};

export const Root = (props: PopoverProps) => {
  const {
    children,
    defaultOpen = false,
    isOpen: controlledOpen,
    onClose,
    onOpenChange,
    ...rootProps
  } = props;
  const [isOpen, toggleOpen] = useState(() => defaultOpen);
  const context = useMemo<PopoverContextValue>(
    () => ({
      isOpen: controlledOpen !== undefined ? controlledOpen : isOpen,
      toggleOpen: (updates: boolean) => {
        if (onOpenChange || onClose) {
          if (onOpenChange) {
            onOpenChange(updates);
          }
          if (!updates && onClose) {
            onClose();
          }
        } else {
          toggleOpen(updates);
        }
      },
    }),
    [isOpen, controlledOpen, onClose, onOpenChange],
  );

  return (
    <PopoverContext.Provider value={context}>
      <RxPopover.Root
        modal={true}
        onOpenChange={context.toggleOpen}
        open={context.isOpen}
        {...rootProps}
      >
        {children}
      </RxPopover.Root>
    </PopoverContext.Provider>
  );
};
