import { Route, Switch } from 'wouter';
import { Stack } from 'styled-system/jsx';
import { Layout } from '~/lib/layout';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
} from '~/lib/routes';
import {
  Header,
  LayoutProvider,
  ScrollContainer,
  useLayout,
} from './ListViewLayout';
import { Divinations } from './content/Divinations';
import { Journals } from './content/Journals';
import { SelectorDropdown } from './ui/SelectorDropdown';
import { ThunderDot } from './ui/ThunderDot';

const PageHeader = () => {
  const { scrollElement } = useLayout();
  return (
    <Stack direction="row" gap={4}>
      <SelectorDropdown size="lg" scrollElement={scrollElement} />
    </Stack>
  );
};

const InnerContent = () => {
  return (
    <Layout.Root>
      <Header />
      <ScrollContainer>
        <Layout.PageContent>
          <Stack direction="column" gap={4} px={[3.5, 5]}>
            <PageHeader />
            <Switch>
              <Route path={DIVINATION_ROUTE} component={Divinations} />
              <Route path={JOURNAL_ROUTE} component={Journals} />
              <Route path={LIST_DIVINATIONS_ROUTE} component={Divinations} />
              <Route path={LIST_JOURNALS_ROUTE} component={Journals} />
            </Switch>
          </Stack>
        </Layout.PageContent>
      </ScrollContainer>
      <ThunderDot />
    </Layout.Root>
  );
};

export const ListView = () => {
  return (
    <LayoutProvider>
      <InnerContent />
    </LayoutProvider>
  );
};
