import { DateTime } from 'luxon';
import { memo } from 'react';
import { type ListDivinationsItem } from '@ichingio/types';
import {
  GridItem,
  Hexagram,
  Image,
  type ImageTransformOptions,
} from '@ichingio/ui';
import { getDivinationRoute } from '~/lib/routes';

const imageOptions: ImageTransformOptions = {
  width: 340,
};

export const DivinationGridItem = memo(
  function DivinationGridItem(props: {
    item: ListDivinationsItem;
    onNavigate: (to: string) => void;
  }) {
    const { item, onNavigate } = props;

    return (
      <GridItem.Root px={1.5} py={1}>
        <GridItem.Content
          onPress={() => onNavigate(getDivinationRoute(item.data.divinationId))}
          shadow={['xl', 'lg']}
        >
          <Image borderRadius="initial">
            <Image.R2 src={item.data.imageUrl} options={imageOptions} />
          </Image>
          <GridItem.Overlay>
            <Hexagram
              width={['82%', '78%', '78%', '76%', '78%']}
              height={['68%', '63%', '63%', '62%', '65%']}
            >
              {item.data.yao.map((yao, index) => {
                return (
                  <Hexagram.Line
                    key={`${yao}-${index}`}
                    yao={yao}
                    visual={
                      (item.data.imageBrightness || 0) > 0.5
                        ? 'alphaDark'
                        : 'alphaLight'
                    }
                  />
                );
              })}
            </Hexagram>
          </GridItem.Overlay>
        </GridItem.Content>
        <GridItem.Description>
          {DateTime.fromMillis(item.data.createdAt).toLocaleString(
            DateTime.DATE_MED,
          )}
        </GridItem.Description>
      </GridItem.Root>
    );
  },
  (prevProps, nextProps) => prevProps.item.id === nextProps.item.id,
);
