import { useSpring, animated } from '@react-spring/web';
import { useRef } from 'react';
import {
  mergeProps,
  type AriaButtonProps,
  type LongPressProps,
  useButton,
  useLongPress,
} from 'react-aria';
import { type HTMLStyledProps, styled } from 'styled-system/jsx';

const InnerTouchable = styled(animated.div, {
  base: {
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    width: 'fit-content',
  },
});

export type InnerTouchableProps = HTMLStyledProps<typeof InnerTouchable>;
export type TouchableProps = AriaButtonProps &
  Omit<LongPressProps, 'isDisabled'> &
  InnerTouchableProps & {
    minOpacity?: number;
  };

export const omitAriaProps = (props: TouchableProps): InnerTouchableProps => {
  const {
    isDisabled: _isDisabled,
    onPress: _onPress,
    onPressStart: _onPressStart,
    onPressEnd: _onPressEnd,
    onLongPress: _onLongPress,
    onLongPressStart: _onLongPressStart,
    onLongPressEnd: _onLongPressEnd,
    ...styledProps
  } = props;

  return styledProps;
};

const Touchable = (props: TouchableProps) => {
  const { children, minOpacity = 0.42, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);
  const { longPressProps } = useLongPress(rest);
  const styles = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: isPressed ? minOpacity : 1,
    },
  });

  return (
    <InnerTouchable
      {...omitAriaProps(rest)}
      {...mergeProps(buttonProps, longPressProps)}
      style={styles}
    >
      {children}
    </InnerTouchable>
  );
};

export default Touchable;
