import { animated, config, useTransition } from '@react-spring/web';
import { type PropsWithChildren } from 'react';
import { useLocation } from 'wouter';
import { Spacer } from 'styled-system/jsx';
import { SearchIcon, SettingsIcon } from '@ichingio/icons';
import { IconButton } from '@ichingio/ui';
import { useNavigateTrigger } from '~/feat/Search';
import { Layout, FloatingHeader, createLayoutContext } from '~/lib/layout';
import { SETTINGS_ROUTE } from '~/lib/routes';
import { ActionMenu } from './ui/ActionMenu';
import { SelectorDropdown, useSelectorVisibility } from './ui/SelectorDropdown';

export const { LayoutProvider, useLayout } = createLayoutContext();

const SearchTrigger = () => {
  const { openSearchModal } = useNavigateTrigger();

  return (
    <IconButton onPress={() => openSearchModal()}>
      <SearchIcon size="md" />
    </IconButton>
  );
};

const Settings = () => {
  const [_, navigate] = useLocation();

  return (
    <IconButton onPress={() => navigate(SETTINGS_ROUTE)}>
      <SettingsIcon size="md" />
    </IconButton>
  );
};

const TransitionSelector = () => {
  const { scrollElement } = useLayout();
  const isVisible = useSelectorVisibility(scrollElement);
  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  return transitions((style, shouldRender) =>
    shouldRender ? (
      <animated.div style={style}>
        <SelectorDropdown scrollElement={scrollElement} />
      </animated.div>
    ) : null,
  );
};

export const Header = () => {
  const { scrollElement } = useLayout();

  return (
    <FloatingHeader.Root scrollElement={scrollElement}>
      <FloatingHeader.Content pl={[3, 5]} pr={[2, 3, 3, 4]}>
        <TransitionSelector />
        <Spacer />
        <ActionMenu />
        <SearchTrigger />
        <Settings />
      </FloatingHeader.Content>
    </FloatingHeader.Root>
  );
};

export const ScrollContainer = (props: PropsWithChildren) => {
  const { children } = props;
  const { setScrollElement } = useLayout();

  return (
    <Layout.ScrollContainer ref={setScrollElement}>
      {children}
    </Layout.ScrollContainer>
  );
};
