import { Box, styled } from 'styled-system/jsx';

export const Body = styled(Box, {
  base: {
    height: '100%',
    px: 4,
    py: 4,
    width: '100%',
  },
});

const Card = styled(Box, {
  base: {
    borderRadius: 8,
    borderWidth: 0,
    overflow: 'hidden',
    width: '100%',
  },
  variants: {
    visual: {
      simple: {},
      fancy: {
        shadow: ['xl', '2xl'],
      },
    },
  },
  defaultVariants: {
    visual: 'simple',
  },
});

export default Card;
