import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type LayoutState<V extends string> = {
  visibleElements: Set<V>;
  scrollElement: HTMLDivElement | null;
};

export const createLayoutContext = <V extends string>(
  initialValue?: Partial<LayoutState<V>>,
) => {
  const useLayoutContext = () => {
    const [state, setState] = useState<LayoutState<V>>(() => ({
      visibleElements: new Set(),
      scrollElement: null,
      ...initialValue,
    }));

    const setScrollElement = useCallback(
      (ref: HTMLDivElement) =>
        setState((prevState) => ({ ...prevState, scrollElement: ref })),
      [],
    );

    const updateVisibleElements = useCallback(
      (updater: (prevVisible: Set<V>) => Set<V>) => {
        setState((prevState) => {
          return {
            ...prevState,
            visibleElements: updater(prevState.visibleElements),
          };
        });
      },
      [],
    );

    return useMemo(
      () => ({
        ...state,
        setScrollElement,
        updateVisibleElements,
      }),
      [state, setScrollElement, updateVisibleElements],
    );
  };

  type LayoutContextValue = ReturnType<typeof useLayoutContext>;

  const LayoutContext = createContext<LayoutContextValue>(
    {} as LayoutContextValue,
  );

  const useLayout = () => useContext(LayoutContext);

  const LayoutProvider = (props: PropsWithChildren) => (
    <LayoutContext.Provider value={useLayoutContext()}>
      {props.children}
    </LayoutContext.Provider>
  );

  return {
    LayoutProvider,
    useLayout,
  };
};
