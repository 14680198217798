import { animated, config, useSpring } from '@react-spring/web';
import throttle from 'lodash/throttle';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  Stack,
  styled,
  type StackProps,
  type HTMLStyledProps,
} from 'styled-system/jsx';
import { useRefGetter } from '@ichingio/hooks';
import { quantizeNumber } from '@ichingio/utils';

type HeaderContextValue = { isVisible: boolean };

const HeaderContext = createContext<HeaderContextValue>({ isVisible: true });

export const Content = (props: StackProps) => {
  const { children, ...stackProps } = props;
  const { isVisible } = useContext(HeaderContext);
  const styles = useSpring({
    from: {
      opacity: 1,
    },
    to: {
      opacity: isVisible ? 1 : 0,
    },
    config: config.stiff,
  });

  return (
    <animated.div style={{ ...styles, width: '100%' }}>
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        gap={0}
        height="3rem"
        pl={[1, 1, 3, 2.5]}
        pr={[2, 3, 3, 2]}
        userSelect="none"
        width="100%"
        {...stackProps}
      >
        {children}
      </Stack>
    </animated.div>
  );
};

const AnimatedHeader = styled(animated.header);

export const Root = (
  props: HTMLStyledProps<'header'> & { scrollElement: HTMLDivElement },
) => {
  const { children, scrollElement, ...headerProps } = props;
  const [isVisible, toggleVisibility] = useState(true);
  const getIsVisible = useRefGetter(isVisible);

  useEffect(() => {
    if (!scrollElement) {
      return;
    }

    let prevScrollTop = Math.floor(scrollElement.scrollTop);
    let prevQuantized = prevScrollTop;

    const checkVisibility = throttle(() => {
      const quantized = quantizeNumber(Math.round(scrollElement.scrollTop), 16);

      if (quantized !== prevQuantized) {
        const { clientHeight, scrollHeight } = scrollElement;
        const scrollTop = Math.floor(scrollElement.scrollTop);
        const currentVisibility = getIsVisible();
        const lowerThreshold = scrollHeight - scrollTop - clientHeight;
        const updatedVisibility =
          (scrollTop < 48 || scrollTop < prevScrollTop) && lowerThreshold > 32;

        if (currentVisibility !== updatedVisibility) {
          toggleVisibility(updatedVisibility);
        }

        prevScrollTop = scrollTop;
      }

      prevQuantized = quantized;
    }, 50);

    scrollElement.addEventListener('scroll', checkVisibility);

    return () => {
      scrollElement.removeEventListener('scroll', checkVisibility);
    };
  }, [scrollElement, getIsVisible]);

  const styles = useSpring({
    from: {
      y: '0rem',
    },
    to: {
      y: isVisible ? '0rem' : '-3rem',
    },
    config: { ...config.stiff, clamp: true },
  });

  const context = useMemo<HeaderContextValue>(
    () => ({ isVisible }),
    [isVisible],
  );

  return (
    <HeaderContext.Provider value={context}>
      <AnimatedHeader
        backdropFilter="blur(1rem)"
        background="stone.50/75"
        pt="env(safe-area-inset-top)"
        position="fixed"
        top={0}
        zIndex={1}
        width="100%"
        style={{ ...headerProps.style, ...styles }}
        {...headerProps}
      >
        {children}
      </AnimatedHeader>
    </HeaderContext.Provider>
  );
};
