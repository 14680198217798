import * as RxDropdownMenu from '@radix-ui/react-dropdown-menu';
import { animated, config, useTransition } from '@react-spring/web';
import {
  type PropsWithChildren,
  type ReactNode,
  forwardRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { InnerIconButton } from '../Button/IconButton';

type DropdownContextValue = {
  isOpen: boolean;
  toggleOpen: (updates: boolean) => void;
};

const DropdownContext = createContext<DropdownContextValue>(
  {} as DropdownContextValue,
);

const InnerItem = styled(RxDropdownMenu.Item, {
  base: {
    alignItems: 'center',
    color: 'text',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    fontSize: ['1rem', '0.95rem'],
    gap: 2,
    minHeight: 9,
    outline: 'none',
    px: 3.5,
    transition: 'background-color 168ms linear',
    _hover: {
      background: 'zinc.50',
    },
    '&[data-highlighted]': {
      background: 'zinc.50',
    },
    '&[data-disabled]': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
});

export type DropdownItemProps = Omit<
  RxDropdownMenu.DropdownMenuItemProps,
  'disabled'
> &
  JsxStyleProps & {
    icon?: ReactNode;
    isDisabled?: boolean;
  };

export const Item = (props: DropdownItemProps) => {
  const { children, icon, isDisabled = false, ...itemProps } = props;

  return (
    <InnerItem disabled={isDisabled} {...itemProps}>
      {icon} {children}
    </InnerItem>
  );
};

const InnerContent = styled(animated(RxDropdownMenu.Content), {
  base: {
    background: 'white',
    borderRadius: 8,
    minWidth: 'fit-content',
    outline: 'none',
    py: 1.5,
    shadow: 'lg',
    width: '10.68rem',
    zIndex: 10,
  },
});

export const Arrow = styled(RxDropdownMenu.Arrow, { base: { fill: 'white' } });
export const Portal = styled(RxDropdownMenu.Portal, {});

export type DropdownContentProps = Pick<
  RxDropdownMenu.DropdownMenuContentProps,
  'align' | 'alignOffset' | 'side' | 'sideOffset' | 'children'
> &
  JsxStyleProps & {
    hasArrow?: boolean;
  };

const getSideOffset = (side: DropdownContentProps['side']) => {
  switch (side) {
    case 'bottom': {
      return -6;
    }
    case 'top': {
      return 6;
    }
    default: {
      return 0;
    }
  }
};

export const Content = forwardRef<HTMLDivElement, DropdownContentProps>(
  function DropdownContent(props, ref) {
    const {
      align = 'center',
      children,
      hasArrow = false,
      side = 'bottom',
      sideOffset = 0,
      ...contentProps
    } = props;
    const { isOpen } = useContext(DropdownContext);
    const transitions = useTransition(isOpen, {
      from: { opacity: 0.84, y: getSideOffset(side) },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: getSideOffset(side) },
      config: {
        ...config.default,
        mass: 1,
        tension: 200,
        friction: 26,
      },
      expires: true,
    });

    return (
      <Portal forceMount>
        {transitions((styles, shouldRender) =>
          shouldRender ? (
            <InnerContent
              align={align}
              forceMount
              loop
              ref={ref}
              side={side}
              sideOffset={sideOffset}
              style={styles}
              {...contentProps}
            >
              {children}
              {hasArrow && <Arrow />}
            </InnerContent>
          ) : null,
        )}
      </Portal>
    );
  },
);

export const Trigger = styled(RxDropdownMenu.Trigger, {
  base: {
    opacity: 1,
    outline: 'none',
    '&[data-state="open"]': {
      opacity: 0.32,
    },
    '&[data-state="closed"]': {
      opacity: 1,
    },
  },
});

export type DropdownIconTriggerProps = RxDropdownMenu.DropdownMenuTriggerProps &
  JsxStyleProps &
  PropsWithChildren;

// Note: we must use the inner icon button component here instead of the Aria composition so that Radix can bind its own handlers.
export const IconTrigger = forwardRef<
  HTMLButtonElement,
  DropdownIconTriggerProps
>(function IconTrigger(props, ref) {
  return (
    <Trigger asChild>
      <InnerIconButton {...props} ref={ref} />
    </Trigger>
  );
});

type CommonnDropdownProps = Omit<
  RxDropdownMenu.DropdownMenuProps,
  'open' | 'modal'
>;
export type DropdownProps = CommonnDropdownProps & {
  isOpen?: boolean;
  onClose?: () => void;
};

export const Root = (props: DropdownProps) => {
  const {
    children,
    defaultOpen = false,
    isOpen: controlledOpen,
    onClose,
    onOpenChange,
    ...rootProps
  } = props;
  const [isOpen, toggleOpen] = useState(() => defaultOpen);
  const context = useMemo<DropdownContextValue>(
    () => ({
      isOpen: controlledOpen !== undefined ? controlledOpen : isOpen,
      toggleOpen: (updates: boolean) => {
        if (onOpenChange || onClose) {
          if (onOpenChange) {
            onOpenChange(updates);
          }
          if (!updates && onClose) {
            onClose();
          }
        } else {
          toggleOpen(updates);
        }
      },
    }),
    [isOpen, controlledOpen, onClose, onOpenChange],
  );

  return (
    <DropdownContext.Provider value={context}>
      <RxDropdownMenu.Root
        modal={true}
        onOpenChange={context.toggleOpen}
        open={context.isOpen}
        {...rootProps}
      >
        {children}
      </RxDropdownMenu.Root>
    </DropdownContext.Provider>
  );
};
