import { type ComponentProps, forwardRef } from 'react';
import { Flex, Spacer, Stack, styled } from 'styled-system/jsx';
import { type JsxStyleProps } from 'styled-system/types';
import { CheckIcon, ChevronLeftIcon } from '@ichingio/icons';
import {
  Dropdown,
  type DropdownProps,
  type DropdownContentProps,
  type DropdownItemProps,
} from '../Dropdown';
import Text from '../Text';

export type NavDropdownTriggerProps = Omit<ComponentProps<'button'>, 'ref'> &
  JsxStyleProps & { size?: 'md' | 'lg' };

export const Trigger = forwardRef<HTMLButtonElement, NavDropdownTriggerProps>(
  function Trigger(props, ref) {
    const { children, size = 'md', ...buttonProps } = props;

    return (
      <Dropdown.Trigger asChild>
        <styled.button
          ref={ref}
          cursor="pointer"
          color="text"
          userSelect="none"
          outline="none"
          _hover={{ opacity: 0.96 }}
          _active={{ opacity: 0.94 }}
          {...buttonProps}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <Text color="inherit" fontWeight={600} size={size}>
              {children}
            </Text>
            <ChevronLeftIcon size="sm" transform="rotate(-90deg)" mt="-1.5px" />
          </Stack>
        </styled.button>
      </Dropdown.Trigger>
    );
  },
);

export type NavDropdownItemProps = DropdownItemProps & { isSelected?: boolean };

export const Item = (props: NavDropdownItemProps) => {
  const { children, onSelect, isSelected = false, icon } = props;

  return (
    <Dropdown.Item
      bg="transparent"
      color="text"
      onSelect={onSelect}
      position="relative"
      px={0}
      minHeight={8}
      css={{
        _hover: {
          bg: 'transparent',
          color: 'primary',
        },
        '&[data-highlighted]': {
          bg: 'transparent',
          color: 'primary',
        },
      }}
    >
      <Stack
        direction="row"
        gap={2}
        pl={8}
        pr={4}
        alignItems="center"
        width="100%"
      >
        <Text color="inherit">{children}</Text>
        {icon && (
          <>
            <Spacer minWidth={4} />
            <Flex
              alignItems="center"
              color="zinc.500"
              height="100%"
              justifyContent="center"
              mt="1px"
            >
              {icon}
            </Flex>
          </>
        )}
      </Stack>
      {isSelected && (
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          left={0}
          position="absolute"
          top="50%"
          transform="translate(8px, -50%)"
          width={5}
          mt="1px"
        >
          <CheckIcon size="sm" />
        </Flex>
      )}
    </Dropdown.Item>
  );
};

export type NavDropdownContentProps = DropdownContentProps;

export const Content = forwardRef<HTMLDivElement, NavDropdownContentProps>(
  function DropdownContent(props, ref) {
    const { children, align = 'start', ...contentProps } = props;

    return (
      <Dropdown.Content
        align={align}
        backdropFilter="blur(4px)"
        bg="rgba(255, 255, 255, 0.95)"
        minWidth="fit-content"
        py={2}
        ref={ref}
        sideOffset={2}
        {...contentProps}
      >
        <Stack direction="column" gap="1px">
          {children}
        </Stack>
      </Dropdown.Content>
    );
  },
);

export type NavDropdownProps = DropdownProps;

export const Root = (props: NavDropdownProps) => {
  const { children, ...dropdownProps } = props;

  return <Dropdown.Root {...dropdownProps}>{children}</Dropdown.Root>;
};
