import { useSpring, animated } from '@react-spring/web';
import { forwardRef } from 'react';
import { type AriaButtonProps } from 'react-aria';
import { cva } from 'styled-system/css';
import { type HTMLStyledProps, styled } from 'styled-system/jsx';
import { useButton, omitAriaProps } from './Button';

export const iconButtonStyles = cva({
  base: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: 8,
    color: 'text',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'center',
    outline: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.35,
      _hover: { opacity: 0.35 },
    },
    '@media (hover: none)': {
      _hover: { opacity: 1 },
    },
    '@media (hover: hover)': {
      _hover: { opacity: 0.95 },
    },
  },
  variants: {
    size: {
      sm: {
        height: 9,
        minHeight: 9,
        minWidth: 9,
        width: 9,
      },
      md: {
        height: 10,
        minHeight: 10,
        minWidth: 10,
        width: 10,
      },
      lg: {
        height: 12,
        minHeight: 12,
        minWidth: 12,
        width: 12,
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const InnerIconButton = styled(animated.button, iconButtonStyles);

export type InnerIconButtonProps = HTMLStyledProps<typeof InnerIconButton>;
export type IconButtonProps = AriaButtonProps & InnerIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const { children, isDisabled, ...rest } = props;
    const { props: buttonProps, isPressed } = useButton(props, ref);
    const initialOpacity = isDisabled ? 0.35 : 1;
    const styles = useSpring({
      from: {
        opacity: initialOpacity,
      },
      to: {
        opacity: isPressed && !isDisabled ? 0.42 : initialOpacity,
      },
    });

    return (
      <InnerIconButton {...omitAriaProps(rest)} {...buttonProps} style={styles}>
        {children}
      </InnerIconButton>
    );
  },
);
