import { animated, useSpring } from '@react-spring/web';
import { useQuery } from 'convex/react';
import { Flex, Stack } from 'styled-system/jsx';
import { LighteningIcon, PlusIcon } from '@ichingio/icons';
import { api } from '@ichingio/server/api';
import { Card, Heading, Link, Text } from '@ichingio/ui';
import { useOpenLink } from '~/lib/links';
import { ABOUT_PAGE_URL, DIVINATION_GUIDE_URL } from '~/lib/routes';

export const EmptyState = () => {
  return (
    <Card boxShadow="md" visual="fancy" borderRadius={12}>
      <Card.Body bg="neutral.800/98" p={[4, 5]}>
        <Stack gap={3}>
          <Stack gap={1}>
            <Heading size="sm" color="white">
              Welcome to iching.io!
            </Heading>
            <Text color="white">
              To get started on your first divination, just tap the{' '}
              {<LighteningIcon color="white" display="inline" size="sm" />} or{' '}
              {<PlusIcon color="white" display="inline" size="sm" />} icon and
              choose an image. You can attach journals to divinations, or start
              by recording your thoughts.
            </Text>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};

const JustEmptyState = () => {
  return (
    <Flex maxWidth="28rem">
      <EmptyState />
    </Flex>
  );
};

const TutorialFauxModal = () => {
  const openLink = useOpenLink();

  const styles = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <Flex
      alignItems="center"
      bg="rgba(255, 255, 255, 0.75)"
      height="100%"
      justifyContent="center"
      left={0}
      padding={3}
      position="fixed"
      top={0}
      width="100%"
      zIndex={0}
    >
      <animated.div style={styles}>
        <Flex direction="column" maxWidth="28rem" marginTop="-4vh">
          <EmptyState />
          <Card>
            <Card.Body p={[4, 5]}>
              <Text>
                For more information about the I Ching and using this app, visit
                our{' '}
                {
                  <Link
                    href={DIVINATION_GUIDE_URL}
                    onClick={async () =>
                      await openLink({ url: DIVINATION_GUIDE_URL })
                    }
                  >
                    divination guide
                  </Link>
                }{' '}
                and{' '}
                {
                  <Link
                    href={ABOUT_PAGE_URL}
                    onClick={async () =>
                      await openLink({ url: ABOUT_PAGE_URL })
                    }
                  >
                    about
                  </Link>
                }{' '}
                pages.
              </Text>
            </Card.Body>
          </Card>
        </Flex>
      </animated.div>
    </Flex>
  );
};

export const WelcomeTutorial = () => {
  const achievements = useQuery(api.users.loadAchievements);
  if (typeof achievements === 'undefined') {
    return null;
  }

  if (achievements && achievements.hasCompletedWelcome) {
    return <JustEmptyState />;
  }

  return <TutorialFauxModal />;
};
