import { type PluginListenerHandle } from '@capacitor/core';
import { Keyboard as CapacitorKeyboard } from '@capacitor/keyboard';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import usePlatform from './usePlatform';

export type KeyboardState = {
  height: number;
  isOpen: boolean;
  isReady: boolean;
};

export type KeyboardEvents = 'show' | 'hide' | 'visible' | 'hidden';
export type ListenerPayload = { height: number };
export type KeyboardListener = (payload: ListenerPayload) => void;
export type Unregister = () => Promise<void>;

type CapacitorKeyboardProps = Pick<typeof CapacitorKeyboard, 'show' | 'hide'>;

export interface KeyboardApi extends CapacitorKeyboardProps {
  on: (type: KeyboardEvents, listener: KeyboardListener) => void;
  off: (type: KeyboardEvents, listener?: KeyboardListener) => void;
  toggleAccessories: (isVisible: boolean) => Promise<void>;
}

class Keyboard implements KeyboardApi {
  protected listeners: Map<KeyboardEvents, KeyboardListener[]>;
  protected handlers: PluginListenerHandle[];
  protected isNative: boolean;
  isReady: boolean;

  constructor(isNative: boolean) {
    this.isNative = isNative;
    this.isReady = false;
    this.listeners = new Map();
    this.handlers = [];
  }

  async show(): Promise<void> {
    if (this.isNative) {
      await CapacitorKeyboard.show();
    }
  }

  async hide(): Promise<void> {
    if (this.isNative) {
      await CapacitorKeyboard.hide();
    }
  }

  async toggleAccessories(isVisible: boolean) {
    if (this.isNative) {
      await CapacitorKeyboard.setAccessoryBarVisible({ isVisible });
    }
  }

  on(type: KeyboardEvents, listener: KeyboardListener) {
    this.listeners.set(type, [...(this.listeners.get(type) || []), listener]);
  }

  off(type: KeyboardEvents, listener?: KeyboardListener) {
    this.listeners.set(
      type,
      listener
        ? this.listeners.get(type) || [].filter((l) => l !== listener)
        : [],
    );
  }

  protected dispatch(type: KeyboardEvents, payload: ListenerPayload) {
    for (const listener of this.listeners.get(type)) {
      listener(payload);
    }
  }

  register(onReady: () => void): Unregister {
    const registrations: Promise<PluginListenerHandle>[] = [];
    const unregister: Unregister = async () => {
      for (const handler of this.handlers) {
        await handler.remove();
      }
      this.handlers = [];
    };
    if (this.handlers.length) {
      unregister();
    }
    if (this.isNative) {
      registrations.push(
        CapacitorKeyboard.addListener('keyboardWillShow', (info) =>
          this.dispatch('show', { height: info.keyboardHeight }),
        ),
      );
      registrations.push(
        CapacitorKeyboard.addListener('keyboardDidShow', (info) =>
          this.dispatch('visible', { height: info.keyboardHeight }),
        ),
      );
      registrations.push(
        CapacitorKeyboard.addListener('keyboardWillHide', () =>
          this.dispatch('hide', { height: 0 }),
        ),
      );
      registrations.push(
        CapacitorKeyboard.addListener('keyboardDidHide', () =>
          this.dispatch('hidden', { height: 0 }),
        ),
      );
    }
    for (const reg of registrations) {
      reg.then((handler) => this.handlers.push(handler));
    }
    Promise.all(this.handlers).then(() => {
      this.isReady = true;
      onReady();
    });

    return unregister;
  }
}

export type KeyboardContextValue = {
  keyboard: KeyboardApi;
  state: KeyboardState;
};

export const KeyboardContext = createContext<KeyboardContextValue>(
  {} as KeyboardContextValue,
);

export const useKeyboardApi = () => {
  const { isNative } = usePlatform();
  const keyboard = useMemo(() => new Keyboard(isNative), [isNative]);
  const [state, setState] = useState<KeyboardState>(() => ({
    height: 0,
    isOpen: false,
    isReady: false,
  }));

  useEffect(() => {
    const events: KeyboardEvents[] = ['show', 'hide', 'visible', 'hidden'];
    for (const e of events) {
      keyboard.on(e, (payload) => {
        setState((prevState) => {
          return {
            ...prevState,
            isOpen: payload.height !== 0,
            height: payload.height,
          };
        });
      });
    }
    const unregister = keyboard.register(() => {
      setState((prevState) => {
        return {
          ...prevState,
          isReady: true,
        };
      });
    });

    return () => {
      unregister();
      for (const e of events) {
        keyboard.off(e);
      }
    };
  }, [keyboard]);

  return useMemo(() => ({ keyboard, state }), [keyboard, state]);
};

export const useKeyboard = (props?: {
  accessories?: boolean;
}): KeyboardState => {
  const { accessories = false } = props || {};
  const { keyboard, state } = useContext(KeyboardContext);
  useEffect(() => {
    if (keyboard) {
      keyboard.toggleAccessories(accessories);
    }
  }, [keyboard, accessories]);

  return state;
};
