import { animated, useSpring, useTransition } from '@react-spring/web';
import { useMutation, useQuery } from 'convex/react';
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'wouter';
import { Flex, Stack, styled } from 'styled-system/jsx';
import { useDeferredValue } from '@ichingio/hooks';
import { CloseIcon, FileTextIcon, LighteningIcon } from '@ichingio/icons';
import { nanoid } from '@ichingio/nanoid';
import { api } from '@ichingio/server/api';
import {
  Button,
  Popover,
  Text,
  Touchable,
  type ButtonProps,
} from '@ichingio/ui';
import { useDivinationFlow } from '~/feat/DivinationFlow';
import { getJournalRoute } from '~/lib/routes';

const useThunderContext = () => {
  const [isOpen, setOpen] = useState(false);
  const [_, navigate] = useLocation();
  const { startNewDivination } = useDivinationFlow();
  const createWithDivination = useMutation(api.journals.createWithDivination);
  const achievements = useQuery(api.users.loadAchievements);
  const completeWelcomeAchievement = useMutation(
    api.users.completeWelcomeAchievement,
  );
  const startDivination = useCallback(() => {
    setOpen(false);
    startNewDivination();
  }, [startNewDivination]);
  const createJournal = useCallback(async () => {
    try {
      const createdId = await createWithDivination({ id: nanoid() });

      if (createdId) {
        if (!achievements || !achievements.hasCompletedWelcome) {
          await completeWelcomeAchievement();
        }

        setOpen(false);
        navigate(getJournalRoute(createdId));
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    achievements,
    completeWelcomeAchievement,
    createWithDivination,
    navigate,
  ]);

  return useMemo(
    () => ({
      createJournal,
      isOpen,
      setOpen,
      startDivination,
    }),
    [isOpen, startDivination, createJournal],
  );
};

type ThunderContextValue = ReturnType<typeof useThunderContext>;

const ThunderContext = createContext<ThunderContextValue>(
  {} as ThunderContextValue,
);

const ThunderProvider = (props: PropsWithChildren) => (
  <ThunderContext.Provider value={useThunderContext()}>
    {props.children}
  </ThunderContext.Provider>
);

const InnerOverlay = styled(animated.div, {
  base: {
    backdropFilter: 'blur(0.5px)',
    bg: 'rgba(255, 255, 255, 0.68)',
    display: 'flex',
    height: '100%',
    inset: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 1,
  },
});

const Overlay = () => {
  const { isOpen, setOpen } = useContext(ThunderContext);
  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions((styles, shouldRender) =>
    shouldRender ? (
      <InnerOverlay onClick={() => setOpen(false)} style={styles} />
    ) : null,
  );
};

const ThunderItem = (props: ButtonProps) => {
  const { children, ...buttonProps } = props;

  return (
    <Button
      bg="neutral.800/88"
      border="none"
      borderRadius={8}
      color="white"
      minHeight={11}
      outline="none"
      pl={4}
      pr={5}
      px={5}
      shadow="lg"
      width="fit-content"
      {...buttonProps}
    >
      <Text color="inherit" size="md" fontWeight={500}>
        {children}
      </Text>
    </Button>
  );
};

const DivinationItem = () => {
  const { startDivination } = useContext(ThunderContext);

  return (
    <ThunderItem
      icon={<LighteningIcon size="sm" />}
      onPress={() => startDivination()}
    >
      Divination
    </ThunderItem>
  );
};

const JournalItem = () => {
  const { createJournal } = useContext(ThunderContext);
  const [isLoading, setLoading] = useState(false);
  const handlePress = useCallback(async () => {
    setLoading(true);
    try {
      await createJournal();
    } catch {
      setLoading(false);
    }
  }, [createJournal]);

  return (
    <ThunderItem
      icon={<FileTextIcon size="sm" />}
      isLoading={isLoading}
      onPress={() => handlePress()}
    >
      Journal
    </ThunderItem>
  );
};

const MenuRoot = (props: PropsWithChildren) => {
  const { children } = props;
  const { isOpen, setOpen } = useContext(ThunderContext);

  return (
    <Popover.Root isOpen={isOpen} onOpenChange={setOpen}>
      {children}
    </Popover.Root>
  );
};

const MenuContent = () => {
  const { isOpen } = useContext(ThunderContext);
  const deferredOpen = useDeferredValue(isOpen, false, 68);
  const divinationStyles = useSpring({
    from: { opacity: 0, y: 24 },
    to: isOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: 24 },
  });
  const journalStyles = useSpring({
    from: { opacity: 0, y: 24 },
    to: deferredOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: 24 },
  });

  return (
    <Popover.Content
      align="end"
      alignOffset={2}
      bg="none"
      side="top"
      sideOffset={12}
      shadow="none"
    >
      <Stack alignItems="end" direction="column" gap={2} px={2}>
        <animated.div style={divinationStyles}>
          <DivinationItem />
        </animated.div>
        <animated.div style={journalStyles}>
          <JournalItem />
        </animated.div>
      </Stack>
    </Popover.Content>
  );
};

const Trigger = () => {
  const clientY = useRef(0);
  const { isOpen, setOpen, startDivination } = useContext(ThunderContext);
  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute' },
  });

  return (
    <Popover.Anchor
      onTouchStart={(e) => {
        clientY.current = e.touches[0].clientY;
      }}
      onTouchMove={(e) => {
        if (clientY.current - e.touches[0].clientY > 50) {
          setOpen(true);
        }
      }}
    >
      <Flex
        backdropFilter="blur(0.25rem)"
        bg="#e9ebfe/98"
        borderRadius="9999px"
        height="3.65rem"
        shadow="xl"
        transition="transform 280ms linear"
        width="3.65rem"
        {...(isOpen ? { transform: 'scale(0.96)' } : {})}
      >
        <Touchable
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          onLongPress={() => setOpen(true)}
          onPress={(e) => {
            e.continuePropagation();
            startDivination();
          }}
          width="100%"
        >
          {transitions((styles, currentOpen) => (
            <animated.div style={styles}>
              {currentOpen ? (
                <CloseIcon color="#545454" size="lg" />
              ) : (
                <LighteningIcon color="#545454" size="lg" />
              )}
            </animated.div>
          ))}
        </Touchable>
      </Flex>
    </Popover.Anchor>
  );
};

export const ThunderDot = () => {
  return (
    <ThunderProvider>
      <Overlay />
      <MenuRoot>
        <Flex
          position="fixed"
          bottom={['1.5rem', '1rem']}
          right={['0.5rem', '0.75rem']}
          zIndex={1}
        >
          <Trigger />
        </Flex>
        <MenuContent />
      </MenuRoot>
    </ThunderProvider>
  );
};
