import { type PropsWithChildren, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { Center } from 'styled-system/jsx';
import { Loader } from '@ichingio/ui';
import { BreakpointsProvider, KeyboardProvider } from '@ichingio/viewport';
import { DivinateProvider } from '~/feat/DivinationFlow';
import { DivinationProvider } from '~/feat/DivinationView';
import { JournalProvider } from '~/feat/JournalEditor';
import { SearchProvider } from '~/feat/Search';
import { useAuthentication } from '~/lib/auth';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
  LOGIN_ROUTE,
  SETTINGS_ROUTE,
} from '~/lib/routes';
import ListView from '~/screens/ListView';

const LoadingOverlay = () => (
  <Center width="100%" height="100%">
    <Loader />
  </Center>
);

const Auth = lazy(() => import('~/screens/Auth'));
const Settings = lazy(() => import('~/screens/Settings'));

const CommonProviders = (props: PropsWithChildren) => (
  <BreakpointsProvider>
    <KeyboardProvider>{props.children}</KeyboardProvider>
  </BreakpointsProvider>
);

const AppProviders = (props: PropsWithChildren) => (
  <SearchProvider>
    <JournalProvider>
      <DivinationProvider>
        <DivinateProvider>{props.children}</DivinateProvider>
      </DivinationProvider>
    </JournalProvider>
  </SearchProvider>
);

const App = () => {
  const { isAuthenticated, isLoading } = useAuthentication();

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <CommonProviders>
      {isAuthenticated ? (
        <AppProviders>
          <Suspense>
            <Switch>
              <Route path={LIST_DIVINATIONS_ROUTE} component={ListView} />
              <Route path={LIST_JOURNALS_ROUTE} component={ListView} />
              <Route path={DIVINATION_ROUTE} component={ListView} />
              <Route path={JOURNAL_ROUTE} component={ListView} />
              <Route path={SETTINGS_ROUTE} component={Settings} />
              <Redirect to={LIST_DIVINATIONS_ROUTE} />
            </Switch>
          </Suspense>
        </AppProviders>
      ) : (
        <Suspense fallback={<LoadingOverlay />}>
          <Redirect to={LOGIN_ROUTE} />
          <Route path={LOGIN_ROUTE} component={Auth} />
        </Suspense>
      )}
    </CommonProviders>
  );
};

export default App;
