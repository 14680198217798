import { forwardRef } from 'react';
import { Flex, Stack, type FlexProps } from 'styled-system/jsx';
import { useBreakpoints } from '@ichingio/viewport';

export const Header = forwardRef<HTMLDivElement, FlexProps>(
  function Header(props, ref) {
    const { children, ...flexProps } = props;

    return (
      <Flex
        backdropFilter="blur(1rem)"
        background="stone.50/75"
        position="fixed"
        pt="env(safe-area-inset-top)"
        ref={ref}
        width="100%"
        zIndex={1}
        {...flexProps}
      >
        <Stack
          alignItems="center"
          direction="row"
          flexShrink={0}
          gap={1}
          height={['3rem', '2.68rem']}
          pl={[1, 1, 3, 2.5]}
          pr={[2, 3, 3, 2]}
          userSelect="none"
          width="100%"
        >
          {children}
        </Stack>
      </Flex>
    );
  },
);

export const PageContent = forwardRef<HTMLDivElement, FlexProps>(
  function PageContent(props, ref) {
    const { children, ...flexProps } = props;

    return (
      <Flex
        flexDirection="column"
        maxWidth="128rem"
        minHeight="100%"
        mx="auto"
        pb="6rem"
        ref={ref}
        width="100%"
        {...flexProps}
      >
        {children}
      </Flex>
    );
  },
);

export const Root = forwardRef<HTMLDivElement, FlexProps>(
  function Root(props, ref) {
    const { children, ...flexProps } = props;

    return (
      <Flex
        alignItems="center"
        direction="column"
        flexGrow={1}
        height="100%"
        position="relative"
        ref={ref}
        touchAction="manipulation"
        userSelect="none"
        width="100%"
        zIndex={0}
        {...flexProps}
      >
        {children}
      </Flex>
    );
  },
);

export const ScrollContainer = forwardRef<HTMLDivElement, FlexProps>(
  function ScrollContainer(props, ref) {
    const { children, ...flexProps } = props;
    const { breakpoint } = useBreakpoints();

    return (
      <Flex
        flexGrow={1}
        justifyContent="center"
        overflowX="hidden"
        overflowY="auto"
        position="relative"
        pt={
          breakpoint === 1
            ? 'calc(env(safe-area-inset-top) + 3rem)'
            : 'calc(env(safe-area-inset-top) + 2.68rem)'
        }
        ref={ref}
        touchAction="pan-y"
        width="100%"
        {...flexProps}
      >
        <Flex direction="column" flexGrow={1} flexShrink={0} width="100%">
          {children}
        </Flex>
      </Flex>
    );
  },
);
