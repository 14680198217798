import {
  Image,
  R2,
  Img,
  type ImageR2Props,
  type ImageTransformOptions,
} from './Image';

export { type ImageR2Props, type ImageTransformOptions };

export default Object.assign(Image, {
  R2,
  Img,
});
