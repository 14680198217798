import { type PropsWithChildren } from 'react';
import { KeyboardContext, useKeyboardApi } from '../hooks/useKeyboard';

export const KeyboardProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const context = useKeyboardApi();

  return (
    <KeyboardContext.Provider value={context}>
      {children}
    </KeyboardContext.Provider>
  );
};
