import { type MouseEventHandler, type TouchEventHandler, useMemo } from 'react';

// Absolutely seriously prevents all mouse and touch events from firing so that TipTap doesn't lose state or focus.
export const useFocusTrap = <T = HTMLDivElement>() => {
  return useMemo(() => {
    const mouseTrap: MouseEventHandler<T> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const touchTrap: TouchEventHandler<T> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleMouseUp: (onClick?: () => void) => MouseEventHandler<T> =
      (onClick) => (e) => {
        mouseTrap(e);

        if (onClick) {
          onClick();
        }
      };
    const handleTouchEnd: (onClick?: () => void) => TouchEventHandler<T> =
      (onClick) => (e) => {
        touchTrap(e);

        if (onClick) {
          onClick();
        }
      };

    return (onClick?: () => void) => {
      return {
        onClick: mouseTrap,
        onMouseDown: mouseTrap,
        onMouseUp: handleMouseUp(onClick),
        onTouchEnd: handleTouchEnd(onClick),
        onTouchMove: touchTrap,
        onTouchStart: touchTrap,
      };
    };
  }, []);
};
