import { useMemo } from 'react';

export type GridRow<Item extends { id: string }> = {
  key: string;
  items: Item[];
};

export const useGridRows = <Item extends { id: string }>(
  items: Item[] | undefined,
  columnCount: number,
) => {
  const { rows } = useMemo<{
    rows: GridRow<Item>[];
  }>(
    () =>
      (items || [])?.reduce<{
        rows: GridRow<Item>[];
        temp: Item[];
      }>(
        (res, d, index) => {
          res.temp.push(d);

          if (res.temp.length === columnCount || index === items.length - 1) {
            res.rows.push({
              key: res.temp.map((d) => d.id).join(','),
              items: [...res.temp],
            });

            res.temp = [];
          }

          return res;
        },
        { temp: [], rows: [] },
      ),
    [columnCount, items],
  );

  return rows;
};
