import { useSpring, animated } from '@react-spring/web';
import {
  forwardRef,
  type ReactNode,
  type ComponentPropsWithRef,
  type ForwardedRef,
  type MutableRefObject,
} from 'react';
import {
  type AriaButtonProps,
  type PressEvent,
  useButton as useAriaButton,
  useObjectRef,
} from 'react-aria';
import { type HTMLStyledProps, Stack, styled } from 'styled-system/jsx';
import Loader from '../Loader';
import { type ButtonVariantProps, buttonStyle } from './lib/style';

export { PressEvent };

const InnerButton = styled(animated.button, buttonStyle);

export type InnerButtonProps = HTMLStyledProps<typeof InnerButton> &
  ButtonVariantProps;

export type ButtonProps = AriaButtonProps &
  InnerButtonProps & {
    isLoading?: boolean;
    icon?: ReactNode;
  };

export const useButton = (
  props: AriaButtonProps,
  externalRef?:
    | ForwardedRef<HTMLButtonElement>
    | MutableRefObject<HTMLButtonElement>,
): { props: ComponentPropsWithRef<'button'>; isPressed: boolean } => {
  const ref = useObjectRef<HTMLButtonElement>(externalRef);
  const { buttonProps, ...rest } = useAriaButton(props, ref);

  return {
    ...rest,
    props: buttonProps,
  };
};

export const omitAriaProps = (props: ButtonProps): InnerButtonProps => {
  const {
    isDisabled: _isDisabled,
    isLoading: _isLoading,
    onPress: _onPress,
    onPressStart: _onPressStart,
    onPressEnd: _onPressEnd,
    ...styledProps
  } = props;

  return styledProps;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const { children, icon, isLoading, ...rest } = props;
    const { props: buttonProps, isPressed } = useButton(props, ref);
    const styles = useSpring({
      from: {
        opacity: 1,
      },
      to: {
        opacity: isPressed ? 0.68 : 1,
      },
    });

    return (
      <InnerButton
        {...omitAriaProps(rest)}
        {...buttonProps}
        style={styles}
        ref={ref}
      >
        {isLoading ? (
          <Loader color="inherit" />
        ) : (
          <Stack
            direction="row"
            gap={1.5}
            alignItems="center"
            justifyContent="center"
          >
            {icon}
            {children}
          </Stack>
        )}
      </InnerButton>
    );
  },
);
